import React, { useState, useEffect, useRef } from 'react';
import { Box, Center, Text, VStack, HStack, Flex, Image, Button, useBreakpointValue, CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IoPerson } from "react-icons/io5";
import { IoPersonOutline } from 'react-icons/io5';
import PrayerRoomRegistrationView from './PrayerRoomRegistrationView';

import SidebarSmall from '../../Navigators/SidebarSmall';
import PrayerRoomOnboarding from './PrayerRoomOnboarding';
import { useData } from '../../App';

import { getAuth } from 'firebase/auth';

import { Helmet } from 'react-helmet';

const PrayerRoomPage = () => {

  const auth = getAuth();

  const user = auth.currentUser;

    const { 
        isSideBarVisible,
        PrayerRoomRegisteredUser,
    
    
    
      } = useData();


    const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <HStack justifyContent="center" alignItems="center" height="100vh">

      <Helmet>
        <title>{`PrayerRooms`}</title>
        <meta name="description" content="Meet your prayer partner for one-on-one prayer" />
        <meta charset="UTF-8" />
      </Helmet>

      {isSideBarVisible && (
        <SidebarSmall


        />
      )}
         <Center mt={isSmallScreen ?  "1rem": null} 
     height="100vh" >


{!PrayerRoomRegisteredUser  ? (

          <PrayerRoomOnboarding />

) : (
<>
<PrayerRoomRegistrationView />

</>

)}
      

          </Center>

          </HStack>
        );
      }
  

export default PrayerRoomPage