
import { userInformationRef, userInformationRefSS, PRRegisteredUsersRef, get, set, child } from '../../Utilities/firebase'

const PrayerRoom = () => {

}

export default PrayerRoom


/*
// FULL
export const getNumberOfMembers = async () => {
  try {
    const snapshot = await get(userInformationRef); // Get data directly from the reference
    if (snapshot.exists()) {
      const totalNumberOfMembers = snapshot.size; // Get the number of children directly

      return totalNumberOfMembers; // Return the total count
    }
    return 0; // Return 0 if there are no contestants
  } catch (error) {
    console.error("Error fetching contestant data:", error);
    return 0; // Optionally return 0 or handle the error as needed
  }
};
*/

// WITH FILTER 

/*
export const getNumberOfMembers = async () => {
  try {
    const snapshot = await get(userInformationRef); // Get data directly from the reference
    if (snapshot.exists()) {
      let filteredMemberCount = 0; // Counter for filtered members

      // Iterate through the snapshot and count users based on filters
      snapshot.forEach((childSnapshot) => {
        const { country, username } = childSnapshot.val();

        // Apply the filter: only count users from the United States who have a username
        if ((country === 'United States' || country === 'Poland') && username) {
          filteredMemberCount++;
        }
      });

      return filteredMemberCount; // Return the count of filtered members
    }
    return 0; // Return 0 if there are no members
  } catch (error) {
    console.error("Error fetching member data:", error);
    return 0; // Optionally return 0 or handle the error as needed
  }
};
*/


// WITH MORE FILTERS

export const getNumberOfMembers = async () => {
  try {
    // Fetch the first snapshot from userInformationRef
    const userSnapshot = await get(userInformationRef);
    // Fetch the second snapshot from PRRegisteredUsersRef
    const prayerRoomSnapshot = await get(PRRegisteredUsersRef);

    let filteredMemberCount = 0; // Counter for filtered members

    // Count members from userInformationRef based on your filter
    if (userSnapshot.exists()) {
      userSnapshot.forEach((childSnapshot) => {
        const { country, username } = childSnapshot.val();

        // Apply the filter: count users from the US or Poland who have a username
        if ((country === 'United States' || country === 'Poland') && username) {
          filteredMemberCount++;
        }
      });
    }

    // Count members from PRRegisteredUsersRef
    if (prayerRoomSnapshot.exists()) {
      prayerRoomSnapshot.forEach((childSnapshot) => {
        const { uid } = childSnapshot.val();

        // Assuming that every user in PRRegisteredUsersRef should be counted (adjust logic if needed)
        if (uid) {
          filteredMemberCount++;
        }
      });
    }

    return filteredMemberCount; // Return the total count of filtered members from both snapshots

  } catch (error) {
    console.error("Error fetching member data:", error);
    return 0; // Optionally return 0 or handle the error as needed
  }
};





export const getPrayerRoomMembersInfo = async () => {
  try {
    const snapshot = await get(userInformationRef);

    if (snapshot.exists()) {
      const usersInfo = [];
      let count = 0; // Counter to limit the number of users

      // Iterate through the snapshot and extract user info
      snapshot.forEach((childSnapshot) => {
        const { name, username, profilePhoto, country } = childSnapshot.val();

        // Filter by country and ensure username exists
        if (count < 25 && (country === 'United States' || country === 'Poland') && name !== "Janet" && name !== "Paco" && name !== "Jane" && name !== "Cindy" && name !== "Breckie" && name !== "Samantha" && name !== "Sam Barton" && name !== "Phil" && name !== "Jill" && name !== "Izabela" && name !== "Jacob" && name !== "DOM" && name !== "Jerome" && name !== "Julka15" && name !== "Anna") {
          usersInfo.push({ name, username, profilePhoto });
          count++; // Increment the counter
        }

        if (count >= 25) {
          return true; // Exit the loop early once 25 users are collected
        }
      });

      return usersInfo; // Return the limited array of users
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching user information:', error.message);
    throw error; // Propagate the error for handling in the calling code
  }
};






/*
// Unlimited pull
  export const getPrayerRoomMembersInfo = async () => {
    try {
    
      const snapshot = await get(userInformationRef);
      
      if (snapshot.exists()) {
        const usersInfo = [];
  
     
        snapshot.forEach((childSnapshot) => {
      
          const userSnapshot = childSnapshot; 
          
    
            const { name, username, profilePhoto } = userSnapshot.val();
            
    
            usersInfo.push({ name, username, profilePhoto });
          
        });
  
    
        return usersInfo;
      } else {
        // No data found under 'userInformationPrayerSendRef'
        return [];
      }
    } catch (error) {
      console.error('Error fetching user information:', error.message);
      throw error; // Propagate the error for handling in the calling code
    }
  };
  */


  export const registerWithPrayerRoom = async (currentUserId) => {
    try {
      // Fetch the user data based on currentUserId from two references
      const userInfoSnapshot = await get(child(userInformationRefSS, `${currentUserId}`));
      const additionalInfoSnapshot = await get(child(userInformationRef, `${currentUserId}`));
  
      // Initialize an object to register the user with uid
      const registrationData = {
        uid: currentUserId
      };
  
      // Check if userInfoSnapshot exists and add email if it does
      if (userInfoSnapshot.exists()) {
        const { email } = userInfoSnapshot.val();
        registrationData.email = email; // Add email to registration data
      }
  
      // Check if additionalInfoSnapshot exists and add name if it does
      if (additionalInfoSnapshot.exists()) {
        const { name, birthday } = additionalInfoSnapshot.val();
        registrationData.name = name;
        registrationData.birthday = birthday;
      }
  
      // Register the user in PRRegisteredUsersRef with the data
      await set(child(PRRegisteredUsersRef, `${currentUserId}`), registrationData);
  
    } catch (error) {
      console.error('Error registering user with Prayer Room:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  };
  

export const checkPRRegisteredUser = async (userId) => {
  try {
    // Fetch the user data from the database
    const snapshot = await get(child(PRRegisteredUsersRef, `${userId}`));

    // Check if the user exists
    if (snapshot.exists()) {
      return true;  // Return true if the user exists
    } else {
      return false; // Return false if the user does not exist
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return false; // Return false in case of an error
  }
};