import React from 'react'
import fonts from '../Utilities/fonts'
import { VStack, Image, Text, Heading, Flex, Spacer, Button, ChakraProvider, Center } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../Utilities/PageTitles';

const AboutUs = () => {

  const navigate = useNavigate();

  usePageTitle('About Us');

  const goToApp = () => {
    navigate('/');

  }

  const goToYenZek = () => {
    window.open('https://www.yenzek.com', '_blank');
  }


  return (
    <ChakraProvider theme={fonts}>
      <Flex flexDirection="row">
        <VStack align="flex-start" pt='6rem' px={{ base: '2rem', sm: '2rem', md: '4rem', lg: '14rem' }}>
          <Heading as="h3" pb="1rem" size="lg" fontWeight="bold" mb="1rem" fontFamily="'Work Sans', sans-serif">
            About PrayerSend
          </Heading>

          <p>PrayerSend is service extension of YenZek provided by <a href="#" onClick={goToYenZek} className="website-link">YenZek Inc.,</a> which allows you to send prayer requests so that people around the world can unite in prayer for you.</p>


          <Center w="100%">
            <VStack>
              <Text fontSize="xl" fontWeight="bold" textAlign="center">Our story</Text>
              <Text textAlign="center">by Nathanael Francis, Founder & CEO</Text>
            </VStack>
          </Center>

          <Text textAlign="justify">

            In the beginning, PrayerSend was born out of a personal experience that left a lasting impact on me. It all started one evening in a small church youth group. There were just five of us, and we were going around sharing our prayer requests. When it was my turn, I simply asked for prayers for the well-being of everyone. However, my request was met with an unexpected reaction.

            The leader of the group scoffed at my request. He went on to pray for everyone else’s specific needs but completely overlooked mine. I felt taken aback and deeply hurt. It seemed my humble prayer for everyone’s well-being wasn’t deemed important enough. That was the last time I attended that church.

            As I reflected on the experience, I started to think about others who might have faced similar judgment or neglect when sharing their prayer requests. What if there was a way for people to submit their prayers without fear of judgment, and where their prayers would be equally valued and prayed for by others?

            This idea stuck with me, and over a weekend, I decided to take action. I built a prototype of what would become PrayerSend. The concept was simple: a platform where anyone could send their prayer requests and have them seen and prayed for by a supportive community. No judgments, no waiting, just genuine prayer and support from people who care.

            And that’s how PrayerSend was born. It’s a place where everyone’s prayers matter, and everyone can find comfort and support in the power of collective prayer. </Text>

          <Center w="100%">
            <VStack>
              <Image src='/PrayerSendLogoText.png' h={20} alt='PrayerSend Logo'></Image>

              <Flex mt="-30px" mb="3px" flexDirection="column">
                <Text fontSize={10} color="gray" >from</Text>

                <Image src='/yenzeknamelogo.png' h={8} alt='YenZek Logo'></Image>
              </Flex>
            </VStack>
          </Center>

<Center w="100%">
          <Text textAlign="center">
            <a href="#" onClick={goToApp} className="website-link">Send a prayer request on PrayerSend now</a> and let people from around the world unite in prayer for you.
          </Text>
 </Center>

          <Flex justify="center" pt="2rem" pb="2rem" w="100%">
            <Button
              maxWidth="300px"
              onClick={goToApp}
              size='lg'
              variant="outline"
              bg='white'
              color="black"
              fontWeight="normal"
              as='b'
              _hover={{
                bg: "blue.300",
                color: 'white'
              }}
            >
              Get Started
            </Button>
          </Flex>





          <Spacer mb="10rem" />

        </VStack>

      </Flex>
    </ChakraProvider>


  )
}

export default AboutUs