
import React, { useRef, useEffect, useState } from 'react';
import { Box, Link, Flex, Image, VStack, HStack, Spacer, Button, Tooltip, Text, useBreakpointValue } from '@chakra-ui/react'

import { HiMiniBars3 } from "react-icons/hi2";

//import { TimerVideoSession } from '../pages/TalkCircle/TimerView';

import { getAuth } from 'firebase/auth';

import { useLocation, useNavigate } from 'react-router-dom';

import { useData } from '../App';

//import { removeTalkCircleEntryValues } from '../pages/TalkCircle/TalkCircle';

import { currentUserId } from '../Utilities/firebase';
//import { getPremiumStatus } from '../Payments/getSubscriptionStatus';

//import { checkTalkCircleFreeTrialStatus, getTCFreeTrialEndDate } from '../pages/TalkCircle/TalkCircleRegistration';

const PrayerRoomHeader = () => {
    const auth = getAuth();
    const user = auth.currentUser;


    const { pathname } = useLocation();

    const navigate = useNavigate();

    const isBaseScreen = useBreakpointValue({ base: true, sm: false, md: false });



    const isSmallScreen = useBreakpointValue({ base: true, sm: false, md: false });
    const {



        isHomeSelected,
        setHomeSelected,
        isPrayerRoomSelected,
        setPrayerRoomSelected,
        isNotificationsSelected,
        setNotificationsSelected,
        isAddNewPostSelected,
        setAddNewPostSelected,
        isProfileSelected,
        setProfileSelected,
        isSettingsSelected,
        setSettingsSelected,

        isSideBarVisible, setIsSideBarVisible,
      


    } = useData();


  




    const goToNotifications = () => {

        if (user) {

            setHomeSelected(false);
            setPrayerRoomSelected(false);
            setNotificationsSelected(prevState => !prevState);
            setAddNewPostSelected(false);
            setProfileSelected(false);

            setSettingsSelected(false);

        } else {
            // User is not signed in, navigate to home page
             navigate("/");
        }

    };

    const includedTalkCircleVideoPath = [


        '/prayerrooms/sessions='


    ];


    const TalkCircleVideoPath = includedTalkCircleVideoPath.some(path => pathname.includes(path));


    const goToPrayerRoom = () => {
      

        setHomeSelected(false);
        setPrayerRoomSelected(true);
        setNotificationsSelected(false);
        setAddNewPostSelected(false);
        setProfileSelected(false);

        setSettingsSelected(false);


        window.location.href = "/prayerrooms";

    };


    return (

        <VStack>
            <Box
                position="fixed"
                top="0"
                left="0"
                width="100%"
                bg="rgba(0, 0, 0, 0.88)" // Dark transparent background
               // borderBottom={TalkCircleVideoPath ? "1px solid rgba(100, 100, 100, 0.5)" : "1px solid #e4e4e4"}
                transition="transform 0.3s ease"
                // transform={isOpen ? 'translateY(0)' : 'translateY(100%)'}
                zIndex="2"
                pt="5px"
                pb="5px"
            >




                <HStack
                    pl={['1rem', '1rem', '1rem']} // Responsive padding left
                    pr={['1rem', '1rem', '1rem']} // Responsive padding right
                >
                    <Flex flexDirection="row" alignItems="center">

                        {!isBaseScreen && !TalkCircleVideoPath && (
                            <>
                                <HiMiniBars3

                                    onMouseEnter={() => setIsSideBarVisible(true)}// Set visibility to false when not hovered, but only if not on small screen
                                    onClick={() => setIsSideBarVisible(prevState => !prevState)} // Toggle visibility when clicked, but only if not on small screen
                                    style={{ fontSize: "24px", color: "white", paddingRight: "8px" }}
                                />
                            </>
                        )}

                        <Image
                            pl={isBaseScreen ? null :"18px"}
                            src="/PrayerSendLogoText.png"
                            alt="PrayerSend Logo"
                            maxH="50px"
                            onClick={goToPrayerRoom}


                        />


                    </Flex>

                    <Spacer />

                    <HStack >






                      



                        {/* 
    
                        <Tooltip
                            hasArrow
                            label={"Notifications"}
                            placement='right'
                            ml={1}
                            openDelay={500}
                            display={{ base: "block", md: "none" }}
    
                        >
                            <Flex
                                gap={4}
                                borderRadius={6}
                                p={2}
                                w={{ base: 10, md: "full" }}
                                justifyContent="flex-start" // Align items to the left
                                onClick={goToNotifications}
                                _hover={{
                                    bg: 'gray.100',
                                    transform: "scale(1.022)",
                                }} style={{
                                    transition: "transform 0.1s",
                                }}>
    
    
                                <Flex alignItems="center"> 
    
                                    {isNotificationsSelected ? <IoMdNotifications style={{ fontSize: "24px", fontWeight: "bold" }} /> : <IoMdNotificationsOutline style={{ fontSize: "24px" }} />}
                                    <Box
                                        display={{ base: "none", md: "block" }}
                                        style={{ marginLeft: '16px', fontWeight: isNotificationsSelected ? "bold" : "normal" }}
                                        fontSize="16px">Notifications</Box> 
    
    
                                </Flex>
    
    
                            </Flex>
                        </Tooltip>
    
    
                        <Tooltip
                            hasArrow
                            label={"Events"}
                            placement='right'
                            ml={1}
                            openDelay={500}
                            display={{ base: "block", md: "none" }}
                        >
                            <Flex
                                gap={4}
                                borderRadius={6}
                                p={2}
                                w={{ base: 10, md: "full" }}
                                justifyContent="flex-start"
                                onClick={goToEvents}
                                _hover={{
                                    bg: 'gray.100',
                                    transform: "scale(1.022)",
                                }} style={{
                                    transition: "transform 0.1s",
                                }}>
    
    
                                <Flex alignItems="center">
    
                                    {isEventsSelected ?
                                        <BsCalendarEventFill
                                            style={{
                                                fontSize: "22px",
                                                fontWeight: "bold"
                                            }} /> :
                                        <BsCalendar4Event
                                            style={{
                                                fontSize:
                                                    "22px"
                                            }} />}
    
                                    <Box
                                        display={{ base: "none", md: "block" }}
                                        style={{ marginLeft: '16px', fontWeight: isEventsSelected ? "bold" : "normal" }}
                                        fontSize="16px">Events</Box> 
    
                                </Flex>
                            </Flex>
                        </Tooltip>
    
    */}

                    </HStack>

                </HStack>




            </Box>


           


        </VStack>

    )
}


export default PrayerRoomHeader