import { initializeApp, } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPhoneNumber, onAuthStateChanged, fetchSignInMethodsForEmail } from 'firebase/auth';
import { getDatabase, ref, child, get, equalTo, orderByChild, query, update, remove, set, onValue, push, off} from 'firebase/database';
import { getStorage, getDownloadURL, uploadBytesResumable } from 'firebase/storage';


const prayerSendConfig = {
  apiKey: "AIzaSyAMawddoGeVulxV22zZKm9th8jZwbMESio",
  authDomain: "prayersend-6a238.firebaseapp.com",
  databaseURL: "https://prayersend-6a238-default-rtdb.firebaseio.com",
  projectId: "prayersend-6a238",
  storageBucket: "prayersend-6a238.appspot.com",
  messagingSenderId: "29119644306",
  appId: "1:29119644306:web:bec20cca3f548873c45c4b",
  measurementId: "G-9Y1QRF2V11"
};

// Initialize Firebase
const prayerSendApp = initializeApp(prayerSendConfig, "PrayerSendApp");



const yenzekConfig = initializeApp({

  apiKey: "AIzaSyBI0sfEOeMrn_O4mN-8EkrUUii3DcHE2Pg",
  authDomain: "yenzek-88079.firebaseapp.com",
  databaseURL: "https://yenzek-88079.firebaseio.com",
  projectId: "yenzek-88079",
  storageBucket: "yenzek-88079.appspot.com",
  messagingSenderId: "69222347681",
  appId: "1:69222347681:web:dc6399f2c0af9614f5d1e0",
  measurementId: "G-RGV7MJW9LM"
})
const yenzekApp = initializeApp(yenzekConfig, "YenZekApp");


// YenZek Project
const auth = getAuth(yenzekConfig);
const databaseYenZek = getDatabase(yenzekConfig);
const storageYenZek = getStorage(yenzekConfig);

const userInformationRef = ref(databaseYenZek, "Users Information");
const userInformationRefSS = ref(databaseYenZek, "Users Information SS");
const ProfilesRef = ref(databaseYenZek, "UserProfiles")

const StoryPostsRef = ref(databaseYenZek, "SecretPosts");
const StoriesURLRef = ref(databaseYenZek, "StoryPosts");

const BlockedUsersRef = ref(databaseYenZek, "Blocked Users");


//-----

const userInformationPrayerSendRef = ref(databaseYenZek, "PrayerSend UserInformation");

const NotificationsRef = ref(databaseYenZek, "PrayerSend Notifications");




const PrayerRequestsRef = ref(databaseYenZek, "PrayerRequests");
const PrayersRef = ref(databaseYenZek, "Prayers");


const PRRegisteredUsersRef = ref(databaseYenZek, "PrayerRoomsRegisteredUsers");


const TestimoniesRef = ref(databaseYenZek, "Testimonies");


const EventInvitationRef = ref(databaseYenZek, "Invitations");

const storage = getStorage(yenzekConfig);

// PrayerSend Project

//const database = getDatabase(prayerSendApp);

//const storage = getStorage(prayerSendApp);

// const userInformationRefPrayerSend = ref(database, "Users Information");
// const userInformationRefSSPrayerSend = ref(database, "Users Information SS");
// const ProfilesRefPrayerSend= ref(database, "UserProfiles")

//const PrayerRequestsRef = ref(database, "PrayerRequests");
////const PrayersRef = ref(database, "Prayers");

//const TestimoniesRef = ref(database, "Testimonies");



//const EventInvitationRef = ref(database, "Invitations");






let currentUserId = null; 


// Detect Auth State
onAuthStateChanged(auth, user => {
    if (user != null) {
        // Set user UID when authenticated user is available
        currentUserId = user.uid;
   //     console.log('logged in');
    } else {
        currentUserId = null; // Reset user UID when user is not authenticated
   //     console.log('No user here');
    }
});



export { 
    auth, 
    yenzekApp,
    prayerSendApp,
    signInWithEmailAndPassword, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, 
    signInWithPhoneNumber, 
    child, get, set, ref, equalTo, orderByChild, query, update, remove, onValue, push, off,
    storage, storageYenZek, getDownloadURL, uploadBytesResumable,
    currentUserId, 

    userInformationRef, 
    userInformationRefSS,
    ProfilesRef,
    StoryPostsRef, 
    StoriesURLRef,


   
    userInformationPrayerSendRef,
    PrayerRequestsRef,
    TestimoniesRef,
    PrayersRef,

    EventInvitationRef,

    BlockedUsersRef,


    NotificationsRef,

    PRRegisteredUsersRef,
   

};
