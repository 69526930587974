// ShareOptions.jsx
import React, { useState, useEffect } from "react";
import { VStack, Flex, HStack, Text, Icon, Button } from "@chakra-ui/react";
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, TelegramShareButton, RedditShareButton, LinkedinShareButton, VKShareButton, EmailShareButton } from "react-share";

import { useToast } from "@chakra-ui/react";
// WhatsApp
import { LiaWhatsapp } from "react-icons/lia";
// <LiaWhatsapp />

// Facebook 
import { LiaFacebook } from "react-icons/lia";
// <LiaFacebook />

// Twitter
import { RiTwitterXFill } from "react-icons/ri";
// <RiTwitterXFill />

// Copy Link
import { AiOutlineLink } from "react-icons/ai";
// <AiOutlineLink />

// Instagram
import { LiaInstagram } from "react-icons/lia";
// <LiaInstagram />

// Telegram
import { LiaTelegram } from "react-icons/lia";
// <LiaTelegram />

// Reddit
import { LiaRedditAlien } from "react-icons/lia";
// <LiaRedditAlien />

// LinkedIn
import { LiaLinkedin } from "react-icons/lia";
// <LiaLinkedin />

// VK
import { SlSocialVkontakte } from "react-icons/sl";
// <SlSocialVkontakte />


// Send to Email
import { MdOutlineMailOutline } from "react-icons/md";
// <MdOutlineMailOutline />

import { FaChevronDown } from "react-icons/fa";
// <FaChevronDown />

import { useData } from "./App";


const ShareOptions = ({ shareLink, onCloseModal }) => {

    const [showMore, setShowMore] = useState(false);
    const [shareMessage, setShareMessage] = useState('');

    const toast = useToast();

  
    const handleShareButtonClick = () => {
      onCloseModal(); // Call the onCloseModal function to close the modal
      copyLinkToClipboard();
    };
  
    const copyLinkToClipboard = () => {
      
      const link = shareLink; // Assuming shareLink contains the URL to be copied
      navigator.clipboard.writeText(link)
        .then(() => {
          toast({
            title: "Link copied",
            status: "success",
            duration: 3000, // Optional duration in milliseconds
            isClosable: true,
          });
          setShowMore(false); // Close the panel after copying the link
        })
        .catch(error => {
          console.error('Failed to copy link: ', error);
          // Handle error, if any
        });

  
        
    };


    useEffect(() => {

      setShareMessage('');
      if (shareLink) {
      if (shareLink.includes("listen=")) {
        setShareMessage("Listen to this prayer request on PrayerSend");
        
      } else if (shareLink.includes("prayerrooms")) {
        setShareMessage("Hey! Come pray with me and others LIVE on PrayerSend");
    } else if (shareLink.includes("invitedby")) {
        setShareMessage("Hey! Join me on PrayerSend, where you can send your prayer requests for people around the world to pray for you");
      } else {
        setShareMessage("Join me on PrayerSend, where you can send your prayer requests for people around the world to pray for you");
      }

    }
    }, [shareLink]);

    const sendEmail = () => {

      window.location.href = mailToLink;
    };
  
    const shareOptions = [
      { button: FacebookShareButton, icon: LiaFacebook, label: "Share to Facebook", url: shareLink, hashtag: "#prayersend", title: "", subject: "", body: "" },
      { button: WhatsappShareButton, icon: LiaWhatsapp, label: "Share to WhatsApp",  url: shareLink, title: {shareMessage}, hashtag: "", subject: "", body: "" },
      { button: TelegramShareButton, icon: LiaTelegram, label: "Share to Telegram", url: shareLink, title: {shareMessage}, hashtag: "", subject: "", body: "" },
      { button: TwitterShareButton, icon: RiTwitterXFill, label: "Share to Twitter",  url: shareLink, title: {shareMessage}, hashtag: "", subject: "", body: "" },
      { button: EmailShareButton, icon: MdOutlineMailOutline, label: "Send to Email", onClick: sendEmail, url: shareLink, subject: {shareMessage}, body: {shareMessage} },
      { button: "ShareButton", icon: AiOutlineLink, label: "Copy Link", onClick: copyLinkToClipboard, title: "", hashtag: "", subject: "", body: "" },
      { button: RedditShareButton, icon: LiaRedditAlien, label: "Share to Reddit", url: shareLink, title: {shareMessage}, hashtag: "", subject: "", body: "" },
      { button: LinkedinShareButton, icon: LiaLinkedin, label: "Share to LinkedIn",  url: shareLink, title: {shareMessage}, hashtag: "", subject: "", body: "" },
      { button: VKShareButton, icon: SlSocialVkontakte, label: "Share to VK", url: shareLink, title: {shareMessage}, hashtag: "", subject: "", body: "" },
    ];
    
  
    return (
    
      <VStack alignItems="flex-start" spacing={2} width="100%">
  
  
      {/* Render the first six share buttons */}

      {/* Facebook Share Button */}
      <FacebookShareButton onClick={handleShareButtonClick} className="share-only" url={shareLink} hashtag="#prayersend">
        <HStack spacing={2} className="share-option">
          <Icon as={LiaFacebook} boxSize={6} />
          <Text className="share-option-label">Share to Facebook</Text>
        </HStack>
      </FacebookShareButton>

      {/* Whatsapp Share Button */}
      <WhatsappShareButton onClick={handleShareButtonClick} className="share-only" url={shareLink} title={shareMessage}>
        <HStack spacing={2} className="share-option">
          <Icon as={LiaWhatsapp} boxSize={6} />
          <Text className="share-option-label">Share to WhatsApp</Text>
        </HStack>
      </WhatsappShareButton>

      {/* Telegram Share Button */}
      <TelegramShareButton onClick={handleShareButtonClick} className="share-only" url={shareLink} title={shareMessage}>
        <HStack spacing={2} className="share-option">
          <Icon as={LiaTelegram} boxSize={6} />
          <Text className="share-option-label">Share to Telegram</Text>
        </HStack>
      </TelegramShareButton>

      {/* Twitter Share Button */}
      <TwitterShareButton onClick={handleShareButtonClick} className="share-only" url={shareLink} title={shareMessage}>
        <HStack spacing={2} className="share-option">
          <Icon as={RiTwitterXFill} boxSize={6} />
          <Text className="share-option-label">Share to Twitter</Text>
        </HStack>
      </TwitterShareButton>

      {/* Email Share Button */}
      <EmailShareButton className="share-only" url={shareLink} subject={shareMessage} body={shareMessage}>
        <HStack spacing={2} className="share-option">
          <Icon as={MdOutlineMailOutline} boxSize={6} />
          <Text className="share-option-label">Send to Email</Text>
        </HStack>
      </EmailShareButton>

      {/* Copy Link Button */}
      <Flex onClick={handleShareButtonClick} className="share-only">
        <HStack spacing={2} className="share-option">
          <Icon as={AiOutlineLink} boxSize={6} />
          <Text className="share-option-label">Copy Link</Text>
        </HStack>
      </Flex>
  
      {showMore !== "true"  && (
        <>
         {/* "Show more" button */}
     <Flex justifyContent="center" width="100%" cursor="pointer" onClick={() => setShowMore("true")}>
     <FaChevronDown />
   </Flex>
        </>
    
      )}
   {showMore == "true"  && (

    <>
      {/* Render the last three share buttons */}

      {/* Reddit Share Button */}
      <RedditShareButton onClick={handleShareButtonClick} className="share-only" url={shareLink} title={shareMessage}>
        <HStack spacing={2} className="share-option">
          <Icon as={LiaRedditAlien} boxSize={6} />
          <Text className="share-option-label">Share to Reddit</Text>
        </HStack>
      </RedditShareButton>

      {/* LinkedIn Share Button */}
      <LinkedinShareButton onClick={handleShareButtonClick} className="share-only" url={shareLink} title={shareMessage}>
        <HStack spacing={2} className="share-option">
          <Icon as={LiaLinkedin} boxSize={6} />
          <Text className="share-option-label">Share to LinkedIn</Text>
        </HStack>
      </LinkedinShareButton>

      {/* VK Share Button */}
      <VKShareButton onClick={handleShareButtonClick} className="share-only" url={shareLink} title={shareMessage}>
        <HStack spacing={2} className="share-option">
          <Icon as={SlSocialVkontakte} boxSize={6} />
          <Text className="share-option-label">Share to VK</Text>
        </HStack>
      </VKShareButton>
  
      </>
  

   )}
  
</VStack>

      );
      
      

  };
  
  export default ShareOptions;