import React, { createContext, useState, useContext, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';

import PageLayouts from './PageLayouts';
import WelcomePage from './Home/WelcomePage';
import PrayerRequests from './Home/PrayerRequests';
import PrayerRoomPage from './pages/PrayerRoom/PrayerRoomPage';

import PostPage from './PostPage/PostPage';

import DonatePage from './pages/DonatePage/DonatePage';
import BlessingPage from './pages/BlessingsPage/BlessingPage';


import AboutUs from './WebsitePolicies/AboutUs';
import ContactUs from './WebsitePolicies/ContactUs';
import CommunityGuidelines from './WebsitePolicies/CommunityGuidelines';



import { getUserInfo } from './pages/UserProfilePage/UserProfile';
import InvitationOnboarding from './pages/Invitation/InvitationOnboarding';

import ProfileDeterminator from './Navigators/ProfileDeterminator';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import useUserUsername from './pages/ProfilePage/ProfileUsername';

import { currentUserId } from './Utilities/firebase';


import { checkPRRegisteredUser } from './pages/PrayerRoom/PrayerRoom';

const DataContext = createContext();



export const useData = () => useContext(DataContext);

function App() {


  const { username } = useUserUsername();

  const [PrayerSendDomainName, setPrayerSendDomainName] = useState("https://prayersend.com/");

  const { pathname } = useLocation();


  const [FreeStoriesToListenTo, setFreeStoriesToListenTo] = useState(0);
  const handleClick = () => {
    setFreeStoriesToListenTo(prevCount => prevCount + 1);
  };

  const [newUserSignedUp, setNewUserSignedUp] = useState(true);

  const [isHomeSelected, setHomeSelected] = useState(true);
  const [isPrayerRoomSelected, setPrayerRoomSelected] = useState(false);
  const [isNotificationsSelected, setNotificationsSelected] = useState(false);
  const [isAddNewPostSelected, setAddNewPostSelected] = useState(false);
  const [isProfileSelected, setProfileSelected] = useState(false);
  const [isSettingsSelected, setSettingsSelected] = useState(false);
  const [isUserOnProfilePages, setIsUserOnProfilePages] = useState(false);

  

  const [showShareToPrayerRoom, setShowShareToPrayerRoom] = useState(false);

  const [isSideBarVisible, setIsSideBarVisible] = useState(false);

  const [createdPostAudio, setAudioBlob] = useState(null);
  const [postAudioType, setPostAudioType] = useState(null);
  const [postAudioBlob, setPostAudioBlob] = useState(null);

  const [wasStoryJustPublished, setStoryJustPublished] = useState(false);


  const [createPostAudioDuration, setRecordingTime] = useState(0);
  const [createPostTitle, setStoryTitle] = useState('');
  const [createdPostNameCategory, setNameCategory] = useState('');
  const [createdPostDataCategory, setCategory] = useState('');

  const [createPostBannerTitle, setCreatePostBannerTitle] = useState("Send a prayer request");


  const [inviteSenderName, setInviteSenderName] = useState(false);
  const [inviteSenderProfilePic, setInviteSenderProfilePic] = useState(false);
  const [inviteSenderUsername, setInviteSenderUsername] = useState(false);
  const [inviteSenderUID, setInviteSenderUID] = useState(false);


  const [PrayerRoomRegisteredUser, setPrayerRoomRegisteredUser] = useState(false);

  useEffect(() => {
    const fetchRegisteredStatus = async () => {
      const isRegistered = await checkPRRegisteredUser(currentUserId); // Call the async function
      setPrayerRoomRegisteredUser(isRegistered); // Set the state based on the result

    };

    fetchRegisteredStatus(); // Invoke the function
  }, [currentUserId]);


  const [isLogin, setIsLogin] = useState(true);
  const [isSignUp, setSignUp] = useState(false);
  const [isYenZekHomeOpen, setIsYenZekHomeOpen] = useState(false);
  const [isLoginPathOpen, setIsLoginPathOpen] = useState(false);
  const [isSignUpPathOpen, setIsSignUpPathOpen] = useState(false);




  const [openPrayerRequestPopup, setOpenPrayerRequestPopup] = useState(false);

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showBeforeLoginSignupPopup, setShowBeforeLoginSignupPopup] = useState(false);
  const [showFirstStepSignUp, setShowFirstStepSignUp] = useState(false);
  const [showSecondStepSignUp, setShowSecondStepSignUp] = useState(false);
  const [showThirdStepSignUp, setShowThirdStepSignUp] = useState(false);

  const [showPublisherSettings, setShowPublisherSettings] = useState(false);

  const [showShareToPrayerSend, setShowShareToPrayerSend] = useState(false);

  const [openBlockPopup, setOpenBlockPopup] = useState(false);
  const [personSelectedToBlock, setPersonSelectedToBlock] = useState({
    uid: '',
    name: '',
    username: '',
    profilePhoto: ''
  });



  const [openDeleteStoryPopup, setOpenDeleteStoryPopup] = useState(false);
  const [storySelectedToDelete, setStorySelectedToDelete] = useState({
    postId: '',
    url: '',
    postCaption: '',
    name: '',
    userProfilePhoto: '',
    username: '',
    postAudio: '',
    postAudioDuration: '',
    prayers: '',
    postTimes_Listened: '',
    postDate: '',
  });


  const [openPrayerSenderPopup, setOpenPrayerSenderPopup] = useState(false);
  const [personSelectedToPrayerSend, setPersonSelectedToPrayerSend] = useState({
    uid: '',
    name: '',
    username: '',
    profilePhoto: '',
    postCaption: '',
    postURL: ''
  });

  const [openReportFromProfile, setOpenReportFromProfile] = useState(false);
  const [openReportFromPost, setOpenReportFromPost] = useState(false);
  const [itemSelectedToReport, setItemSelectedToReport] = useState('');


  const [isPageLoading, setPageIsLoading] = useState(false);
  const [isNotFoundPageVisible, setNotFoundPageVisible] = useState(false);

  const [prayerIsPlaying, setPrayerIsPlaying] = useState(false);

  const [prayerRequestIsPlaying, setPrayerRequestIsPlaying] = useState(false);



  const saveRecordingData = (audioBlob, recordingTime, storyTitle) => {



    setAudioBlob(audioBlob);
    setRecordingTime(recordingTime);
    setStoryTitle(storyTitle);

  };


  const [currentURL, savedUrl] = useState('');

  const [profileURL, savedProfileURL] = useState('');



  useEffect(() => {
    // Check if the user is on ProfilePage or ProfileDeterminator components
    const isOnProfilePages = location.pathname.includes(`/${profileURL}`);
    setIsUserOnProfilePages(isOnProfilePages);
  }, [location, profileURL]);



  useEffect(() => {
    const includedYenZekHomPath = ['/stories', '/listen', ...(profileURL ? [`/${profileURL}`] : [])];
    setIsYenZekHomeOpen(includedYenZekHomPath.some(path => pathname.includes(path)));

    const includedLoginPath = ['/login', ''];
    setIsLoginPathOpen(includedLoginPath.some(path => pathname.includes(path)));

    const includedSignUp = ['/signup', 'account/login'];
    setIsSignUpPathOpen(includedSignUp.some(path => pathname.includes(path)));
  }, [pathname]);


  const getExtractedURL = (urlExtracted) => {
    // A> THE URL IS A STORY POST
    // GETTING URL FOR STORY-POSTS

    localStorage.setItem('theSavedURL', urlExtracted);
    savedUrl(urlExtracted);
    //('3.THE EXTRACTED URL LOOKS LIKE THIS: ', urlExtracted);


  };


  const getUserProfilePageURL = (userPageURL) => {

    if (userPageURL) {
      // B> THE URL IS A PROFILE PAGE
      // GETTING URL FOR PROFILE PAGE
      /// IT WILL BE CHANGES TO OTHER THINGS LIKE TESTIMONIES AND PRAYEERS AND PRAYER REQUESTS
      if (userPageURL !== "download" && userPageURL !== "social-reviews") {
        localStorage.setItem('theURL', userPageURL);
        savedProfileURL(userPageURL);
        //console.log('THE SAVED PROFILE URL: ', userPageURL);
        // console.log('2. THE EXTRACTED Username:', userPageURL);

      }

    }
  };
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('requests')) {

      setPrayerRoomSelected(false);
      setIsUserOnProfilePages(false);
    }

    if (currentUrl.includes('prayerrooms')) {

      setPrayerRoomSelected(true);
      setHomeSelected(false);
      setIsUserOnProfilePages(false);
    }

    if (currentUrl.includes(`/${profileURL}`) && profileURL === username) {

      setPrayerRoomSelected(false);
      setHomeSelected(false);
      setIsUserOnProfilePages(true);

    }
  }, []);

  useEffect(() => {

    const currentUrl = window.location.href;
    if (currentUrl.includes('listen')) {
      // A> THE URL IS A STORY POST
      const urlCode = currentUrl.substring(currentUrl.lastIndexOf("=") + 1);
      getExtractedURL(urlCode);
      localStorage.setItem('theSavedURL', urlCode);


    } else {

      // B> THE URL IS A PROFILE PAGE

      // Exclude specific routes
      const excludedRoutes = ['/about', '/contact-us', '/company', '/help', '/terms-of-service', '/privacy-policy', '/community-guidelines', '/faqs', '/brand', '/legal', '/login', '/signup', '/main', '/auth', '/home', '/search', '/explore', '/talkcircle', '/messages', '/chats',];
      if (excludedRoutes.some(route => currentUrl.includes(route))) {

        return; // Exit the useEffect early if the URL contains an excluded route
      }

      const parts = currentUrl.split('/');
      let username = parts[parts.length - 1];
      // console.log('1. THE EXTRACTED Username:', username);
      // Perform further actions with the extracted username

      getUserProfilePageURL(username);

    }
  }, []);

  const [ReferalEventLink, setReferalEventLink] = useState("https://prayersend.com/invitedby=");

  const [ReferalPrayerRoomLink, setReferalPrayerRoomLink] = useState("https://prayersend.com/prayerrooms/invitedby=");


  const getUsernameURL = async (urlExtracted) => {



    const userInfo = await getUserInfo(urlExtracted);
    if (userInfo) {

      setInviteSenderName(userInfo.userName);
      setInviteSenderProfilePic(userInfo.userProfilePhoto);
      setInviteSenderUsername(userInfo.userUsername);
      setInviteSenderUID(userInfo.userUID);
      savedProfileURL(urlExtracted);


    }
  };
 

  useEffect(() => {
    const currentUrl = window.location.href;
    
    if (currentUrl.includes('invitedby=')) {
      const usernameURL = currentUrl.substring(currentUrl.lastIndexOf("=") + 1);
      console.log("Extracted Username URL:", usernameURL); // Log the extracted username URL for debugging
      getUsernameURL(usernameURL); // Call getUsernameURL with the extracted usernameURL
    }
  }, []);


  const [turnOnInvitePage, setTurnOnInvitePage] = useState(false);

  useEffect(() => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('invitedby=') && PrayerRoomRegisteredUser) {

      window.location.replace("/prayerrooms");

      setTurnOnInvitePage(false);
    } else  if (currentUrl.includes('invitedby=') && !PrayerRoomRegisteredUser) {
      setTurnOnInvitePage(true);
    }

  }, []);

  return (

    <DataContext.Provider value={{

      getExtractedURL,
      currentURL, savedUrl,
      profileURL, savedProfileURL,

      postAudioType, setPostAudioType,
      postAudioBlob, setPostAudioBlob,
      createdPostAudio, setAudioBlob,
      createPostAudioDuration, createPostTitle, createdPostNameCategory, createdPostDataCategory,

      FreeStoriesToListenTo, handleClick,

      createPostBannerTitle,




      saveRecordingData,

      wasStoryJustPublished, setStoryJustPublished,
      showPublisherSettings, setShowPublisherSettings,


      showLoginPopup, setShowLoginPopup,
      showBeforeLoginSignupPopup, setShowBeforeLoginSignupPopup,
      showFirstStepSignUp, setShowFirstStepSignUp,
      showSecondStepSignUp, setShowSecondStepSignUp,
      showThirdStepSignUp, setShowThirdStepSignUp,



      isLogin, setIsLogin,
      isSignUp, setSignUp,
      isYenZekHomeOpen, setIsYenZekHomeOpen,
      isLoginPathOpen, setIsLoginPathOpen,
      isSignUpPathOpen,


      showShareToPrayerRoom, setShowShareToPrayerRoom,

      showShareToPrayerSend, setShowShareToPrayerSend,
      ReferalEventLink, setReferalEventLink,



      getUsernameURL,


      inviteSenderName, setInviteSenderName,
      inviteSenderProfilePic, setInviteSenderProfilePic,
      inviteSenderUsername, setInviteSenderUsername,
      inviteSenderUID, setInviteSenderUID,


      getUserProfilePageURL,

      openBlockPopup, setOpenBlockPopup,
      personSelectedToBlock, setPersonSelectedToBlock,

      openPrayerSenderPopup, setOpenPrayerSenderPopup,
      personSelectedToPrayerSend, setPersonSelectedToPrayerSend,

      openDeleteStoryPopup, setOpenDeleteStoryPopup,
      storySelectedToDelete, setStorySelectedToDelete,

      openReportFromProfile, setOpenReportFromProfile,
      openReportFromPost, setOpenReportFromPost,
      itemSelectedToReport, setItemSelectedToReport,

      isPageLoading, setPageIsLoading,
      isNotFoundPageVisible, setNotFoundPageVisible,

      prayerIsPlaying, setPrayerIsPlaying,
      prayerRequestIsPlaying, setPrayerRequestIsPlaying,

      isHomeSelected, setHomeSelected,
      isNotificationsSelected, setNotificationsSelected,
      isAddNewPostSelected, setAddNewPostSelected,
      isProfileSelected, setProfileSelected,

      isSettingsSelected, setSettingsSelected,


      isUserOnProfilePages, setIsUserOnProfilePages,

      newUserSignedUp, setNewUserSignedUp,

      isPrayerRoomSelected, setPrayerRoomSelected,

      isSideBarVisible, setIsSideBarVisible,

      PrayerRoomRegisteredUser, setPrayerRoomRegisteredUser,

      openPrayerRequestPopup, setOpenPrayerRequestPopup,

      turnOnInvitePage, setTurnOnInvitePage,

      ReferalPrayerRoomLink, setReferalPrayerRoomLink,

      PrayerSendDomainName


    }}>
      <PageLayouts>


        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path={`invitedby=${profileURL}`} element={<InvitationOnboarding />} />
          <Route path="/requests" element={<PrayerRequests />} />
          <Route path={`/listen=${currentURL}`} element={<PostPage />} />

          <Route path="/give" element={<DonatePage />} />
          <Route path="/blessings" element={<BlessingPage />} />

          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/community-guidelines" element={<CommunityGuidelines />} />


          <Route path="/prayerrooms" element={<PrayerRoomPage />} />
          <Route path={`/prayerrooms/invitedby=${profileURL}`} element={<PrayerRoomPage />} />

          {/* Keep ProfilePage both ProfilePage and ProfileDeterminator is important !!*/}
          <Route path={`/${profileURL}`} element={<ProfileDeterminator />} />
          <Route path={`/${username}`} element={<ProfilePage />} />



        </Routes>


      </PageLayouts>
    </DataContext.Provider>

  )
}

export default App
