import React from 'react'
import { Text, VStack, HStack, Link, Button, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useData } from './App';

const PrayerRequestNotFound = () => {

    const navigate = useNavigate();
    const {
  
       
        setHomeSelected,
        setPrayerRoomSelected,
        setNotificationsSelected,
        setAddNewPostSelected,
        setProfileSelected,

     
    
      } = useData();

    const goToPrayerRq = () => {

        navigate('/requests');
        setHomeSelected(true);

        setPrayerRoomSelected(false);
        setNotificationsSelected(false);
        setAddNewPostSelected(false);
        setProfileSelected(false);

    
    }
    return (

        <VStack mt="1rem" gap={5}>
           
             

        


            <Text textAlign="center" fontSize="24px" fontWeight="bold" mx="2rem">This prayer requests isn't available anymore</Text>

            <HStack justifyContent="center">
                <Button color="blue.500"    onClick={goToPrayerRq}>Go back to PrayerSend</Button>
            </HStack>

        </VStack>


    );
}


export default PrayerRequestNotFound