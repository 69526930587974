import React, { useState, useEffect } from 'react';
import { Box, Button, HStack, Flex, Text, Image, VStack, } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { getAuth } from 'firebase/auth';

import { useData } from '../../App';


import { getPrayerRoomMembersInfo, getNumberOfMembers } from './PrayerRoom';

import { BsPersonCircle } from "react-icons/bs";


const PrayerRoomMembers = () => {
  // Step 1: State to hold the members' info
  const [membersInfo, setMembersInfo] = useState([]);
  const [membersCount, setMembersCount] = useState(0);


  const [isOnPostPage, setOnPostPage] = useState(false);

  const auth = getAuth();

  const user = auth.currentUser;

  const navigate = useNavigate();

  const {

    PrayerRoomRegisteredUser, setPrayerRoomRegisteredUser,

    showLoginPopup, setShowLoginPopup,

    isHomeSelected,
    setHomeSelected,
    setPrayerRoomSelected,
    setNotificationsSelected,
    setAddNewPostSelected,
    setProfileSelected,

    setSettingsSelected,



  } = useData();

  useEffect(() => {
    const currentUrl = window.location.href;
    
    if (currentUrl.includes('listen=')) {
      setOnPostPage(true);
    } else {
      setOnPostPage(false);

    }
  }, []);

  useEffect(() => {
    const fetchMembersCount = async () => {
      try {
        const count = await getNumberOfMembers(); // Call the function to get the number of members
        setMembersCount(count); // Set the number in state
      } catch (error) {
        console.error("Error fetching contestant count:", error);
      }
    };

    fetchMembersCount(); // Call the function to fetch the count
  }, []); // Empty dependency array means this runs once when the component mounts


  // Step 2: Fetch the members' data when the component mounts
  useEffect(() => {
    const fetchMembersInfo = async () => {
      try {
        // Call the function to get all users' info
        const members = await getPrayerRoomMembersInfo();

        // Set the returned data to the state
        setMembersInfo(members);
      } catch (error) {
        console.error('Error fetching members information:', error);
      }
    };

    fetchMembersInfo(); // Fetch data when component mounts
  }, []); // Empty dependency array means this runs once when the component mounts

  const goToPrayerRoom = () => {
    // Navigate to PrayerRoom
    navigate('/prayerrooms');


    setHomeSelected(false);
    setPrayerRoomSelected(true);
    setNotificationsSelected(false);
    setAddNewPostSelected(false);
    setProfileSelected(false);

    setSettingsSelected(false);

  };

  /*
   <div>
     <h1>Prayer Room Members</h1>
     {membersInfo.length > 0 ? (
       <ul>
         {membersInfo.map((member, index) => (
           <li key={index}>
             <div>
               <img 
                 src={member.profilePhoto || 'defaultProfilePhoto.jpg'} 
                 alt={`${member.name}'s profile`} 
                 width="50" 
               />
             </div>
             <p>Name: {member.name}</p>
             <p>Username: {member.username}</p>
           </li>
         ))}
       </ul>
     ) : (
       <p>No members found.</p>
     )}
   </div>
  
  */

  // Step 3: Render the data in JSX
  return (

    <VStack>

      {!isOnPostPage && (

        <>
          {/*
<HStack>
       <Text fontSize="10px">Win $1000.00 for best Halloween Costume this year! 🎃</Text>
       <Text as="b" color="blue.300" fontSize="10px"    cursor="pointer"  _hover={{
                       transform: "scale(1.022)",
                     }} onClick={openEvents}>Learn more</Text>

       </HStack>
                    */}

          {isHomeSelected && (
            <>
              {/* ANNOUNCEMENTS
  */}
              <Text fontSize="12px" textAlign="center" >Join PrayerRooms LIVE for immediate, one-on-one prayer starting Friday, November 22, 2024.
              </Text>

            </>

          )}

          <Flex alignItems="flex-start" justifyContent="center" >

            <HStack spacing="2" alignItems="center">
              {/* Neon green ball */}
              <Flex alignItems="flex-end">
                <Box w="2" h="2" borderRadius="50%" bg="green.400" boxShadow="0 0 5px 2px rgba(0, 255, 0, 0.8)" />
              </Flex>
              {/* Text       <Text fontSize="100px">JUST DO IT! </Text>*/}


              {membersCount > 1 && (

                <>
                  <Text fontSize="10px" textAlign="center" >{membersCount} {membersCount == 1 ? 'person' : 'people'} joined PrayerRooms</Text>

                </>
              )}



              {isHomeSelected && (

                <Text as="b" color="blue.300" fontSize="10px" cursor="pointer" _hover={{
                  transform: "scale(1.022)",
                }} onClick={goToPrayerRoom}>Join now!</Text>

              )}


            </HStack>
          </Flex>

          <HStack>

            <HStack >

              <HStack pl={{ base: "40px", sm: "40px", md: "140px" }} width="100vw" overflowX="auto" css={{
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                '-ms-overflow-style': 'none',  // For Internet Explorer and Edge
                'scrollbar-width': 'none',  // For Firefox
              }}>

                {membersInfo.length === 0 ? (

                  <></>
                ) : (
                  <>



                    {membersInfo.map((member, index) => (

                      <Box key={index} pt="4" mr="4" onClick={goToPrayerRoom}> {/* Added key prop for unique identification */}
                        {/* Check if isSmallScreen is defined */}


                        <VStack className='glow-effect'>
                          {!member.profilePhoto ? (
                            <>
                              <BsPersonCircle size="50px" color="gray" />
                            </>
                          ) : (
                            <>
                              <Image
                                src={member.profilePhoto}
                                borderRadius='full'
                                boxSize={{ base: "50px", sm: "70px", md: "80px" }}
                                boxShadow="base"
                                className="profile-image-circle"
                                _hover={{
                                  bg: 'gray.100',
                                  transform: "scale(1.022)",
                                }} style={{
                                  transition: "transform 0.1s",
                                }}
                              />
                            </>
                          )}
                        </VStack>

                        {!member.name ? (

                          <Text pt="3px" textAlign="center" fontSize="12px">{member.username}</Text>
                        ) : (
                          <>
                            <Text pt="3px" textAlign="center" fontSize="12px">{member.name}</Text>

                          </>
                        )}


                      </Box>



                    ))}



                  </>
                )}

                {membersCount > 25 && (
                  <Box
                    display="flex" // Use flex for centering
                    alignItems="center"
                    justifyContent="center"
                    mt={2}
                    mr="1rem"
                    borderRadius="full" // Use "full" to ensure it's circular
                    bg="gray.100" // Light gray background
                    width="80px" // Set width for the circle
                    height="30px" // Set height for the circle to match width
                  >
                    <Text fontSize="14px" as="b" color="gray.500">
                      {/* +{lastYearPhotos.length} */}
                      +{membersCount - 25}
                    </Text>
                  </Box>
                )}

              </HStack>
            </HStack>

          </HStack>

        </>
      )}

    </VStack>
  );



}

export default PrayerRoomMembers