
import React, { useState, useEffect } from 'react';
import { Center, Box, Button, HStack, Flex, Text, Image, VStack, Spacer, useBreakpointValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { getAuth } from 'firebase/auth';

import { useData } from '../../App';


import { getPrayerRoomMembersInfo, getNumberOfMembers } from './PrayerRoom';

import { BsPersonCircle } from "react-icons/bs";


import { IoArrowForward } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";

//People/Organization
import { IoPeopleSharp } from "react-icons/io5";

// Angel 
import { GiAngelWings } from "react-icons/gi";

// Send

import { LuSend } from "react-icons/lu";

// Send 

import { BiMessageAltAdd } from "react-icons/bi";

const PrayerRoomRegistrationView = () => {


  // Step 1: State to hold the members' info
  const [membersInfo, setMembersInfo] = useState([]);
  const [membersCount, setMembersCount] = useState(0);


  const isLargeScreen = useBreakpointValue({ base: false, sm: false, md: true, lg: true });

  const auth = getAuth();

  const user = auth.currentUser;

  const navigate = useNavigate();

  const {

    PrayerRoomRegisteredUser, setPrayerRoomRegisteredUser,

    showLoginPopup, setShowLoginPopup,

    isHomeSelected,
    setHomeSelected,

    setPrayerRoomSelected,
    setNotificationsSelected,
    setAddNewPostSelected,
    setProfileSelected,




    setSettingsSelected,

    setStoryJustPublished,
    setShowPublisherSettings,

    showShareToPrayerRoom, setShowShareToPrayerRoom,

    openPrayerRequestPopup, 
    setOpenPrayerRequestPopup,

  } = useData();

  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {
    const fetchMembersCount = async () => {
      try {
        const count = await getNumberOfMembers(); // Call the function to get the number of members
        setMembersCount(count); // Set the number in state
      } catch (error) {
        console.error("Error fetching contestant count:", error);
      }
    };

    fetchMembersCount(); // Call the function to fetch the count
  }, []); // Empty dependency array means this runs once when the component mounts


  // Step 2: Fetch the members' data when the component mounts
  useEffect(() => {
    const fetchMembersInfo = async () => {
      try {
        // Call the function to get all users' info
        const members = await getPrayerRoomMembersInfo();

        // Set the returned data to the state
        setMembersInfo(members);
      } catch (error) {
        console.error('Error fetching members information:', error);
      }
    };

    fetchMembersInfo(); // Fetch data when component mounts
  }, []); // Empty dependency array means this runs once when the component mounts

  const goToPrayerRoom = () => {
    // Navigate to PrayerRoom
    navigate('/prayerrooms');


    setHomeSelected(false);
    setPrayerRoomSelected(true);
    setNotificationsSelected(false);
    setAddNewPostSelected(false);
    setProfileSelected(false);

    setSettingsSelected(false);

  };

  /*
   <div>
     <h1>Prayer Room Members</h1>
     {membersInfo.length > 0 ? (
       <ul>
         {membersInfo.map((member, index) => (
           <li key={index}>
             <div>
               <img 
                 src={member.profilePhoto || 'defaultProfilePhoto.jpg'} 
                 alt={`${member.name}'s profile`} 
                 width="50" 
               />
             </div>
             <p>Name: {member.name}</p>
             <p>Username: {member.username}</p>
           </li>
         ))}
       </ul>
     ) : (
       <p>No members found.</p>
     )}
   </div>
  
  */

  // Step 3: Render the data in JSX

  // JAAAAAAAAAAAAK?

  const handleHover = (color) => {
    document.querySelectorAll('.hoverable').forEach(element => {
      element.style.color = color;
    });


  };

  const resetAudioPosting = () => {
    setStoryJustPublished(false);
    setShowPublisherSettings(false);

}



  const goToHome = () => {


    navigate('/requests');
    setHomeSelected(true);

    setPrayerRoomSelected(false);
    setNotificationsSelected(false);
    setAddNewPostSelected(false);
    setProfileSelected(false);



  };


  const openPrayerRequestAudioUploader = () => {

   // USER ONLY THIS CODE LATER 
    // setOpenPrayerRequestPopup(true);
 

// GOING TO WELCOME PAGE UPLOAD PRAYER REQUESTING (REMOVE THIS CODE LATER)
    setHomeSelected(false);
    setPrayerRoomSelected(false);
    setNotificationsSelected(false);
    setAddNewPostSelected(false);
    setProfileSelected(false);
    setPrayerRoomSelected(false);
    navigate('/');


  };


  const goBack = () => {
    onShowCreatePost();


  };

  const [selectedIcon, setSelectedIcon] = useState(null);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const [storyCategorySelected, setStoryCategorySelected] = useState(false);
  const [category, setCategory] = useState('');
  const [nameCategory, setNameCategory] = useState('');
  const [categoryError, setCategoryError] = useState('');

  const handleClick = (id) => {
    setSelectedIcon(id);
    setStoryCategorySelected(true);
    setCategoryError('');


    if (id) {
      switch (id) {
        case "1":
          //    console.log("Go back to prayer request");
          resetAudioPosting();

          openPrayerRequestAudioUploader();
          break;


        case "2":

        setShowShareToPrayerRoom(true);

          break;

        case "3":
          goToHome();
          resetAudioPosting();

          // console.log("See other prayer requests");

          break;



      }
    }
  };


  const proceedToNextPage = () => {

    if (!storyCategorySelected) {
      setCategoryError("You must choose a selection");
      return;
    }
    // DO SOMETHING HERE

  }

  const IconWithText = ({ icon, text, id }) => (
    <Box
      flexDirection="row"
      borderWidth="1px"
      borderStyle="solid"
      borderColor={selectedIcon === id ? "black" : "gray.200"}
      p={2}
      borderRadius="md"
      width="100%"
      height="100%"
      margin="0.5rem"
      onClick={() => handleClick(id)} // Add onClick event handler
      _hover={{ bg: 'gray.50' }}
      style={{
        cursor: "pointer"

      }}

    >

      <Flex flexDirection="row" justifyContent="flex-start" alignItems="center" gap="7px" ml="1rem">
        {icon({ size: 18 })}

        <Text
          style={{
            fontSize: '14px',
            textAlign: 'center',
            cursor: 'pointer',
            fontWeight: selectedIcon === id ? 'bold' : 'normal',
          }}
        >
          {text}
        </Text>
      </Flex>




    </Box>
  );



  const BigIconWithText = ({ icon, text, id }) => (
    <Box borderWidth="1px"
      borderStyle="solid"
      borderColor={selectedIcon === id ? "black" : "gray.200"}
      p={2}
      borderRadius="md"
      width="120px"
      height="120px"
      margin="0.5rem"
      onClick={() => handleClick(id)} // Add onClick event handler
      _hover={{ bg: 'gray.50' }}
      style={{
        cursor: "pointer"

      }}


    >
      {icon({ size: 18 })}
      <Text
        style={{
          fontSize: "14px",
          marginTop: "8px",
          textAlign: "center",
          cursor: "pointer",
          fontWeight: selectedIcon === id ? "bold" : "normal"

        }}
      >
        {text}
      </Text>
    </Box>
  );






  // JAAAAAAAAAAAAAK?






  return (

    <VStack>

      <VStack mb="5">
        <Text as="b" fontSize={isLargeScreen ? "md" : "sm"} textAlign="center">
          You are now registered!
        </Text>
        <Text as="b" fontSize={isLargeScreen ? "md" : "sm"} textAlign="center">
          PrayerRooms begin Friday, November 22, 2024
        </Text>
        <Text fontSize="xs" textAlign="center">
          Enter our PrayerRooms at any of the following prayer times:
        </Text>
        <Text fontSize="xs" textAlign="center">
          Prayer Times 🙏 :: 9:00 AM EST | 12:00 PM EST | 3:00 PM EST | 7:30 PM EST
        </Text>
      </VStack>


      <Flex alignItems="flex-start" justifyContent="center" >

        <HStack spacing="2" alignItems="center">
          {/* Neon green ball */}
          <Flex alignItems="flex-end">
            <Box w="2" h="2" borderRadius="50%" bg="green.400" boxShadow="0 0 5px 2px rgba(0, 255, 0, 0.8)" />
          </Flex>
          {/* Text       <Text fontSize="100px">JUST DO IT! </Text>*/}


          {membersCount > 1 && (

            <>
              <Text fontSize="10px">{membersCount} {membersCount == 1 ? 'person' : 'people'} joined PrayerRooms</Text>

            </>
          )}



          {isHomeSelected && (

            <Text as="b" color="blue.300" fontSize="10px" cursor="pointer" _hover={{
              transform: "scale(1.022)",
            }} onClick={goToPrayerRoom}>Enter now!</Text>

          )}


        </HStack>
      </Flex>

      <HStack>

        <HStack >

          <HStack pl={{ base: "40px", sm: "40px", md: "140px" }}
            width="100vw"
            overflowX="auto" // Ensures horizontal scrolling if the content overflows
            display="grid"
            gridAutoFlow="column" // Flow items horizontally
            gridTemplateRows="repeat(2, 1fr)" // Fixed 3 rows
            gap={4} // Adjust spacing between items
            css={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '-ms-overflow-style': 'none', // For Internet Explorer and Edge
              'scrollbar-width': 'none', // For Firefox
            }}
          >

            {membersInfo.length === 0 ? (

              <></>
            ) : (
              <>



                {membersInfo.map((member, index) => (

                  <Box key={index} pt="4" mr="4" onClick={goToPrayerRoom}> {/* Added key prop for unique identification */}
                    {/* Check if isSmallScreen is defined */}


                    <VStack className='glow-effect'>
                      {!member.profilePhoto ? (
                        <>
                          <BsPersonCircle size="50px" color="gray" />
                        </>
                      ) : (
                        <>
                          <Image
                            src={member.profilePhoto}
                            borderRadius='full'
                            boxSize={{ base: "40px", sm: "50px", md: "60px" }}
                            boxShadow="base"
                            className="profile-image-circle"
                            _hover={{
                              bg: 'gray.100',
                              transform: "scale(1.022)",
                            }} style={{
                              transition: "transform 0.1s",
                            }}
                          />
                        </>
                      )}
                    </VStack>

                    {!member.name ? (

                      <Text pt="3px" textAlign="center" fontSize="12px">{member.username}</Text>
                    ) : (
                      <>
                        <Text pt="3px" textAlign="center" fontSize="12px">{member.name}</Text>

                      </>
                    )}


                  </Box>



                ))}



              </>
            )}

            {membersCount > 25 && (
              <Box
                display="flex" // Use flex for centering
                alignItems="center"
                justifyContent="center"
                mt={2}
                mr="1rem"
                borderRadius="full" // Use "full" to ensure it's circular
                bg="gray.100" // Light gray background
                width="80px" // Set width for the circle
                height="30px" // Set height for the circle to match width
              >
                <Text fontSize="14px" as="b" color="gray.500">
                  {/* +{lastYearPhotos.length} */}
                  +{membersCount - 25}
                </Text>
              </Box>
            )}

          </HStack>
        </HStack>

      </HStack>



      {/* --------*/}



      <VStack>



        <Box
          position="absolute"
          left="20px"
          top="20px"
          display="flex"
          alignItems="center"
        >
          <>
            <IoArrowBack size={isSmallScreen ? "18px" : "24px"} onClick={goBack} />


          </>



        </Box>

        <HStack>
          <div
            className="glow-effect">


            <GiAngelWings size={isSmallScreen ? "14px" : "18px"} color="white" />
          </div>


          <Text
            pt="10px"
            textAlign="center"
            as='b'
           
            fontSize={isSmallScreen ? "xs" : "sm"} // Conditional font size
          >
            Share a prayer request, if you haven't already
          </Text>
          <div
            className="glow-effect">


            <GiAngelWings size={isSmallScreen ? "14px" : "18px"} color="white" />
          </div>

        </HStack>
       

        {categoryError && <Text fontSize={12} color="red.500">{categoryError}</Text>}


        <Box></Box>
        <Box pt="10px">
          {isSmallScreen ? (

            <>
              <VStack justify="center">


                <IconWithText icon={BiMessageAltAdd} text="Share a prayer request" id="1" />
                <IconWithText icon={LuSend} text="Invite a friend to PrayerSend" id="2" />
                <IconWithText icon={IoPeopleSharp} text="Listen to Prayer Requests" id="3" />

              </VStack>


            </>


          ) : (
            <>


              <HStack justify="center">
                <BigIconWithText icon={BiMessageAltAdd} text="Share a prayer request" id="1" />
                <BigIconWithText icon={LuSend} text="Invite a friend to PrayerSend" id="2" />
                <BigIconWithText icon={IoPeopleSharp} text="Listen to Prayer Requests" id="3" />
              </HStack>





            </>

          )}
        </Box>
        <Spacer />


      </VStack>


    </VStack>
  );



}


export default PrayerRoomRegistrationView